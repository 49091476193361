/**
 * Place any jQuery/helper plugins in here.
 */
$(function () {

    Select2Default();
    Select2RemoteAjax();

    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();

    /**
     * Tooltip setup
     * */
    $('[data-toggle="tooltip"]').tooltip();

    /**
     * Ajax setup
     * */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    /**
     * Checkbox tree for permission selecting
     */
    let permissionTree = $('.permission-tree :checkbox');

    permissionTree.on('click change', function (){
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        } else {
            $(this).siblings('ul').find('input[type="checkbox"]').removeAttr('checked').removeAttr('disabled');
        }
    });

    permissionTree.each(function () {
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        }
    });

    /**
     * Disable submit inputs in the given form
     *
     * @param form
     */
    function disableSubmitButtons(form) {
        form.find('input[type="submit"]').attr('disabled', true);
        form.find('button[type="submit"]').attr('disabled', true);
    }

    /**
     * Enable the submit inputs in a given form
     *
     * @param form
     */
    function enableSubmitButtons(form) {
        form.find('input[type="submit"]').removeAttr('disabled');
        form.find('button[type="submit"]').removeAttr('disabled');
    }

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        if (!$(this).hasClass('ignore_disable')) {
            disableSubmitButtons($(this));
        }
        return true;
    });

    window.FormSubmitDisable = function (){
        $('form').submit(function () {
            disableSubmitButtons($(this));
            return true;
        });
    }

    /**
     * Set submit_type value form
     */
    window.setSubmitType = function (obj){
        let submit_type  = $(obj).val();
        $(obj).parents('form').find('input[name="submit_type"]').val(submit_type);
    }

    /**
     * Loading Button
     */
    $(".btn-loading").click(function() {
        // disable button
        $(this).prop("disabled", true);
        // add spinner to button
        $(this).html(
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Đang xử lý...'
        );
    });

    /**
     * Add a confirmation to a delete button/form
     */
    $('body').on('submit', 'form[name=delete-item]', function(e) {
        e.preventDefault();

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        }).fire({
            title: 'Bạn có chắc bạn muốn xóa mục này?',
            showCancelButton: true,
            confirmButtonText: 'Xác nhận xóa',
            cancelButtonText: 'Để sau',
            icon: 'warning',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.submit()
            } else {
                enableSubmitButtons($(this));
            }
        });

    })
        .on('submit', 'form[name=confirm-item]', function (e) {
            e.preventDefault();

            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-soft-secondary me-2'
                },
                buttonsStyling: false
            }).fire({
                title: 'Bạn có chắc chắn muốn làm điều này?',
                showCancelButton: true,
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                icon: 'warning',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('click', 'a[name=confirm-item]', function (e) {
        /**
         * Add an 'are you sure' pop-up to any button/link
         */
        e.preventDefault();

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-soft-secondary me-2'
            },
            buttonsStyling: false
        }).fire({
            title: 'Bạn có chắc chắn muốn làm điều này?',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
            icon: 'info',
            reverseButtons: true
        }).then((result) => {
            result.value && window.location.assign($(this).attr('href'));
        });
    });

    // Remember tab on page load
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        let hash = $(e.target).attr('href');
        history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
    });

    let hash = window.location.hash;
    if (hash) {
        $('.nav-link[href="'+hash+'"]').tab('show');
    }

    //Load js Modal
    $(".modal").on("show.bs.modal", function(event){
        var button   = $(event.relatedTarget);
        var action   = button.attr('data-remote');
        var backdrop = button.attr('data-bs-backdrop');
        var modal    = $(this);
        if (typeof action !== 'undefined' && action !== ''){
            modal.find(".modal-content").load(action);
        }
        if (typeof backdrop !== 'undefined' && backdrop !== ''){
            modal.attr('data-bs-backdrop','static');
        }
    });

    // Load nội dung cho Offcanvas
    $(".offcanvas").on("show.bs.offcanvas", function(event) {
        var button      = $(event.relatedTarget); // Nút kích hoạt
        var action      = button.attr('data-remote'); // URL cần load
        var backdrop    = button.attr('data-bs-backdrop'); // Thuộc tính backdrop
        var position    = button.attr('data-bs-position'); // Vị trí (start, end, top, bottom)
        var size        = button.attr('data-bs-size'); // Kích thước (sm, md, lg, xl)
        var offcanvas   = $(this); // Tham chiếu tới Offcanvas

        // Kiểm tra nếu có URL để load nội dung
        if (typeof action !== 'undefined' && action !== '') {
            offcanvas.load(action); // Load nội dung vào Offcanvas body
        }

        // Kiểm tra nếu có thuộc tính backdrop
        if (typeof backdrop !== 'undefined' && backdrop !== '') {
            offcanvas.attr('data-bs-backdrop', 'static'); // Đặt backdrop thành static
        }

        // Xử lý vị trí
        if (typeof position !== 'undefined' && position !== '') {
            offcanvas.removeClass('offcanvas-start offcanvas-end offcanvas-top offcanvas-bottom')
                .addClass('offcanvas-' + position); // Thêm lớp vị trí
        }

        // Xử lý kích thước
        if (typeof size !== 'undefined' && size !== '') {
            offcanvas.removeClass('offcanvas-sm offcanvas-md offcanvas-lg offcanvas-xl')
                .addClass('offcanvas-' + size); // Thêm lớp kích thước
        }
    });

    let isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() ||      isMobile.Opera() || isMobile.Windows());
        }
    };

    //Hiển thị dropdown trong table responsive
    if (!isMobile.any()){
        $('.table-responsive').on('show.bs.dropdown', function () {
            $('.table-responsive').css( "overflow", "inherit" );
        }).on('hide.bs.dropdown', function () {
            $('.table-responsive').css( "overflow", "auto" );
        });
    }

    //Hiển thị xem thêm
    window.toggleChevron    =   function (e){
        // Tìm ID của phần tử collapse được kích hoạt hoặc ẩn
        var targetID = $(e.target).attr('id');

        // Tìm tất cả các nút mà 'data-target' hoặc 'href' tương ứng với ID này
        $("[data-bs-target='#" + targetID + "'], [href='#" + targetID + "']").each(function() {
            // Đảo ngược icon trong nút này
            const iconElement = $(this).find("i");

            // Lấy danh sách các lớp cần toggle từ thuộc tính `data-toggle-class`
            let toggleClasses = $(this).attr('data-toggle-class');

            // Nếu không có toggleClasses, sử dụng giá trị mặc định
            toggleClasses = toggleClasses ? toggleClasses.split(' ') : ['la-angle-double-down', 'la-angle-double-up'];

            // Toggle từng lớp trong danh sách
            toggleClasses.forEach(cls => {
                iconElement.toggleClass(cls);
            });

        });
    }

    $('.collapse').on('hidden.bs.collapse', toggleChevron).on('shown.bs.collapse', toggleChevron);

});

/**
 * Select 2
 * **/
window.Select2Default   = function (){
    let select2_default = $(".js_select2_default");
    select2_default.select2({
        language: "vi",
        allowClear: true,
        selectionCssClass: 'js_select2_default',
        searchInputPlaceholder:'Nhập từ khóa tìm kiếm...',
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        dropdownParent: $(this).data('data-dropdown-parent') ? $($(this).data('data-dropdown-parent')) : $(document.body),
    });
}

window.Select2RemoteAjax = function (){
    let select2_remote = $('.js_select2_remote');
    select2_remote.select2({
        language: "vi",
        allowClear: true,
        searchInputPlaceholder:'Nhập từ khóa tìm kiếm...',
        selectionCssClass: 'js_select2_remote',
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        dropdownParent: $(this).data('data-dropdown-parent') ? $($(this).data('data-dropdown-parent')) : $(document.body),
        ajax: {
            url: function () {
                return $(this).attr('data-search')
            },
            dataType: 'json',
            type: "GET",
            quietMillis: 50,
            data: function (params) {
                return {q: params.term}
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            id: item.id,
                            text: item.name,
                            description: item.description,
                            icon: item.icon,
                            color:item.color,
                            price: item.price,
                            right_text: item.right_text,
                            right_description: item.right_description
                        }
                    })
                };
            }
        },
        templateResult: function (data) {
            var $icon;
            if (data.icon !== '' && typeof data.icon !== "undefined"){
                $icon  =  "<div class='select2-result-repository__avatar'><i class='"+data.icon+"'></i></div>";
            } else {
                $icon  =  '';
            }

            var $container = $(
                "<div class='select2-result-repository select2-result select2-result-location clearfix'>"
                + $icon +
                "<div class='select2-result-repository__meta'>" +
                "<div class='select2-result-repository__wrap__left'>" +
                "<div class='select2-result-repository__title'></div>" +
                "<div class='select2-result-repository__description'></div>" +
                "</div>" +
                "<div class='select2-result-repository__wrap__right'>" +
                "<div class='select2-result-repository__note select2-result-repository__right_title'></div>" +
                "<div class='select2-result-repository__note select2-result-repository__right_description'></div>" +
                "</div>" +
                "</div>" +
                "</div>"
            );

            $container.find(".select2-result-repository__title").text(data.text);
            $container.find(".select2-result-repository__description").text(data.description);
            $container.find(".select2-result-repository__price").text(data.price);
            $container.find(".select2-result-repository__right_title").text(data.right_text);
            $container.find(".select2-result-repository__right_description").text(data.right_description);

            return $container;
        }
    }).on('select2:select', function (e) {
        var data = e.params.data;
        if (data.id == 'add'){
            $(this).val(null).trigger('change');
            $($(this).attr('data-trigger')).trigger('click');
        } else  {
            var trigger    =   $(this).attr('data-trigger');
            var fill       =   $(this).attr('data-fill');
            if (typeof trigger !== 'undefined' && trigger !== ''){
                $(trigger).trigger('click');
            }
            if (typeof fill !== 'undefined' && fill !== ''){
                $(fill).val(data.text)
            }
        }
    });
};

window.ProductRemoteAjax = function (){
    var select2_suggest_product = $('.js_select2_product_remote');
    select2_suggest_product.select2({
        language: "vi",
        allowClear: true,
        searchInputPlaceholder:'Nhập từ khóa tìm kiếm tại đây...',
        selectionCssClass: 'js_select2_product_remote',
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        dropdownParent: $(this).data('data-dropdown-parent') ? $($(this).data('data-dropdown-parent')) : $(document.body),
        ajax: {
            url: function () {
                return $(this).attr('data-search')
            },
            dataType: 'json',
            type: "GET",
            quietMillis: 50,
            data: function (params) {
                return {q: params.term}
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            id: item.id,
                            text: item.name,
                            description: item.description,
                            icon: item.icon,
                            code: item.code,
                            price: item.price,
                            duration: item.duration
                        }
                    })
                };
            }
        },
        templateResult: function (data) {

            var $icon;
            if (data.icon !== '' && typeof data.icon !== "undefined"){
                $icon  =  "<div class='select2-result-repository__avatar'><i class='"+data.icon+"'></i></div>";
            } else {
                $icon  =  '';
            }

            var $container = $(
                "<div class='select2-result-repository select2-result select2-result-location clearfix'>"
                + $icon +
                "<div class='select2-result-repository__meta'>" +
                "<div class='select2-result-repository__wrap__left'>" +
                "<div class='select2-result-repository__title'></div>" +
                "<div class='select2-result-repository__description'></div>" +
                "</div>" +
                "<div class='select2-result-repository__wrap__right'>" +
                "<div class='select2-result-repository__note select2-result-repository__code'></div>" +
                "<div class='select2-result-repository__note select2-result-repository__duration'></div>" +
                "</div>" +
                "<div class='select2-result-repository__wrap__right select2-result-repository__price'></div>" +
                "</div>" +
                "</div>"
            );

            $container.find(".select2-result-repository__title").text(data.text);
            $container.find(".select2-result-repository__description").text(data.description);
            $container.find(".select2-result-repository__price").text(data.price);
            $container.find(".select2-result-repository__code").text(data.code);
            $container.find(".select2-result-repository__duration").text(data.duration);

            return $container;
        }
    }).on('select2:select', function (e) {

        var data = e.params.data;
        if (data.id == 'add'){
            $(this).val(null).trigger('change');
            $($(this).attr('data-trigger')).trigger('click');
        } else {

            let table_service = $('#table_list_service');
            $.ajax({
                url: '/admin/product/get-price-rule',
                type: 'POST',
                data: { product_id : data.id }
            }).done(function(data) {

                //Reset = 0
                table_service.find('tr').each(function (){
                    $(this).find('.unit_price_row').val(0);
                });

                //Lấy giá theo sản phẩm
                $.each(data.data, function(key, value) {
                    let price   =   value.price;
                    table_service.find('.js_price_rule_id_'+key).each(function (){
                        $(this).find('.unit_price_row').val(price);
                    });
                });

            });

        }

    });
}

window.Select2SupplierRemoteAjax = function (){
    var select2_suggest = $('.js_supplier_remote_ajax');
    select2_suggest.select2({
        language: "vi",
        allowClear: true,
        searchInputPlaceholder:'Nhập từ khóa tìm kiếm...',
        selectionCssClass: 'js_supplier_remote_ajax',
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        dropdownParent: $(this).data('data-dropdown-parent') ? $($(this).data('data-dropdown-parent')) : $(document.body),
        ajax: {
            url: function () {
                return $(this).attr('data-search')+'&service_id='+$(this).attr('data-service-id')
            },
            dataType: 'json',
            type: "GET",
            quietMillis: 50,
            data: function (params) {
                return {
                    q: params.term
                }
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            id: item.id,
                            text: item.name,
                            description: item.description,
                            icon: item.icon
                        }
                    })
                };
            }
        },
        templateResult: function (data) {
            var $icon;
            if (data.icon !== '' && typeof data.icon !== "undefined"){
                $icon  =  "<div class='select2-result-repository__avatar'><i class='"+data.icon+"'></i></div>";
            } else {
                $icon  =  '';
            }

            var $container = $(
                "<div class='select2-result-repository select2-result select2-result-location clearfix'>"
                + $icon +
                "<div class='select2-result-repository__meta'>" +
                "<div class='select2-result-repository__title'></div>" +
                "<div class='select2-result-repository__description'></div>" +
                "</div>" +
                "</div>"
            );

            $container.find(".select2-result-repository__title").text(data.text);
            $container.find(".select2-result-repository__description").text(data.description);

            return $container;
        }
    }).on('select2:select', function (e) {

        var data = e.params.data;
        if (data.id == 'add'){
            $(this).val(null).trigger('change');
            $($(this).attr('data-trigger')).trigger('click');
        } else {
            if ($(this).closest('tr').find('.js_check_quote').length > 0){
                $(this).closest('tr').find('.js_check_quote').trigger('click');
            }
        }

        setTimeout(function () {
            SynSupplier();
        },10);
    }).on("select2:unselect", function(e) {

        if ($(this).closest('tr').find('.js_check_quote').length > 0){
            $(this).closest('tr').find('.js_check_quote').trigger('click');
        }

        setTimeout(function () {
            SynSupplier();
        },10);
    }).on("select2:open", function(e) {

    }).on("select2:selecting", function(e) {

    }).on('select2:close', function (e) {

    });
};

window.filterSupplier = function (obj){
    let e_supplier_id = $(obj).parents('tr').find('.js_supplier_remote_ajax');
    e_supplier_id.attr('data-service-id',$(obj).val());
    e_supplier_id.val(null).trigger("change");
};

//Select 2 update
(function($) {

    var Defaults = $.fn.select2.amd.require('select2/defaults');

    $.extend(Defaults.defaults, {
        searchInputPlaceholder: ''
    });

    var SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search');

    var _renderSearchDropdown = SearchDropdown.prototype.render;

    SearchDropdown.prototype.render = function(decorated) {

        // invoke parent method
        var $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments));

        this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'));

        return $rendered;
    };
})(window.jQuery);

//Total
(function ($) {
    $.fn.calculateTotal = function () {
        return this.each(function () {
            var $table = $(this);

            //Set mặc định
            calculate();

            // Thêm sự kiện cho radio button hoặc checkbox "Có VAT" và "Không VAT"
            $(".vat_option_total").on("change", calculate);

            //Qua event
            $table.on("keyup mouseup click change", ".unit_price_row, .exchange_rate_row, .quantity_row, .period_row, .vat_row, .vat_type_row, .plus, .minus", calculate);

            // Add format event for unit format input
            $table.on("keyup", ".unit_price_row, .exchange_rate_row", function() {
                let value = $(this).val().replace(/[^\d.]/g, '');
                $(this).val(addCommas(value));
            });

            function calculate() {
                var sum_post_vat_total  = 0;
                var sum_pre_vat_total   = 0;
                var vat_option          = $(".vat_option_total:checked").val();
                $table.find("tr").each(function () {
                    if(typeof vat_option !== "undefined"){
                        $(this).find(".vat_type_row").val(vat_option);
                    }
                    var $row                = $(this);
                    var unitPriceInput      = $row.find(".unit_price_row");
                    var exchangeRateInput   = $row.find('.exchange_rate_row');
                    var quantity            = parseFloat($row.find(".quantity_row").val());
                    var period              = parseFloat($row.find(".period_row").val());
                    var vat                 = parseFloat($row.find(".vat_row").val());
                    var vat_type            = $row.find(".vat_type_row").val();

                    if (!isNaN(quantity) && !isNaN(period)) {

                        var unitPrice       =   parseFloat(unitPriceInput.val().replace(/,/g, '') || 1);
                        var exchange_rate   =   parseFloat((exchangeRateInput.val() || '1').replace(/,/g, '') || 1);
                        var preVat          =   unitPrice * quantity * period * exchange_rate;
                        var postVat         =   Math.round(preVat * (1 + vat / 100));
                        if(typeof vat_type !== "undefined" && vat_type === 'inc-vat'){
                            postVat         =   preVat;
                            preVat          =   Math.round((preVat/(1 + vat/100)));
                        }

                        sum_post_vat_total  += postVat;
                        sum_pre_vat_total   += preVat

                        $row.find(".pre_vat_total_row").text(addCommas(preVat));
                        $row.find(".post_vat_total_row").text(addCommas(postVat));
                    }

                });

                if ($('.sum_post_vat_total').length > 0){
                    $(".sum_post_vat_total").text(addCommas(sum_post_vat_total));
                }

                if ($('.sum_pre_vat_total').length > 0){
                    $(".sum_pre_vat_total").text(addCommas(sum_pre_vat_total));
                }

                //TH tính toán tiếp sau khi có giá tổng
                if ($('.total_quantity_est').length > 0){
                    $('.total_quantity_est').keyup();
                }
            }
        });
    };
})(window.jQuery);

/**
 * Allows you to add data-method="METHOD to links to automatically inject a form
 * with the method on click
 *
 * Example: <a href="{{route('customers.destroy', $customer->id)}}"
 * data-method="delete" name="delete-item">Delete</a>
 *
 * Injects a form with that's fired on click of the link with a DELETE request.
 * Good because you don't have to dirty your HTML with delete forms everywhere.
 */
window.addDeleteForms = function (){
    $('[data-method]').append(function () {
        if (!$(this).find('form').length > 0) {

            var data_name = 'delete-item';
            if ($(this).attr('data-name')){
                data_name = $(this).attr('data-name');
            }

            return "\n<form action='" + $(this).attr('href') + "' method='POST' name='" + data_name + "' style='display:none'>\n" +
                "<input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "<input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                '</form>\n';
        } else { return '' }
    })
        .attr('href', '#')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}
