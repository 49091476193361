/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2');

require('../bootstrap');
require('bootstrap');

// Bootstrap-notify
require('bootstrap-notify')

// Select2
require("select2");
require("select2/dist/js/i18n/vi.js");

// Custom
require('./custom');

// Boilerplate
require('../plugins');

//Select2
// require("select2");
// require("select2/dist/js/i18n/vi.js");

//Bootstrap-notify
// require('bootstrap-notify')

//Boilerplate
// require('../plugins');

//import Vue from 'vue';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
