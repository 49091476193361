//pushNotify
window.pushNotify = function (message,type, z_index = 1031) {
    $.notify({
        message: message
    },{
        type: type,
        z_index: z_index,
        allow_dismiss: true,
        template: '<div data-notify="container" class="col-xs-11 col-sm-4 alert-dismissible bs5-alert-dismissible alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="btn-close" data-bs-dismiss="alert" aria-label="Close" data-notify="dismiss"></button>' +
            '<span data-notify="icon"></span> <span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span><div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
    });
}

window.number_format = function (obj){
    var _x = $(obj).val().replace(/[^0-9]+/g, "");
    if(_x>999999999999){_x=999999999999}
    $(obj).val(addCommas(_x));
}

//Format
window.addCommas = function (nStr,symbol = ','){
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = "";
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + symbol + '$2');
    }
    return x1 + x2;
}
